<template>
     <div class="page-content">
        <div  class="row">
            <pre-video src="https://cdn.joeltankard.com/website/projects/waffle-run/video-waffle.mp4" />
            <div>
                <h2>How it started</h2>
                <p>I had just finished my first year studying design at college. Over the break, I was looking for a creative outlet. I'm not typically into playing games — except for Mario Kart, I will destroy you at Mario Kart. But the idea of creating a game excited me. I'd only ever built websites before; launching a full product was a new challenge.</p>
                <p>Earlier that year, I'd come across an indie game forum. On it, I found <a href="https://tangramgames.dk/games/sienna/" target="_blank">Sienna</a>, an unfinished, one-button platformer. It was so fun and easy to play that I couldn't believe the developer had just given up on it. So, I decided to take it over. I emailed the developers for permission and got the thumbs up.</p>
            </div>
        </div>
        <div  class="row row-reverse">
            <img src="https://cdn.joeltankard.com/website/projects/waffle-run/charaters-pixel-art-2.png" />
            <div>
                <h2>Concept</h2>
                <p>Waffle Run is an endless runner game. The player has only one control: tap. Tapping allows the character to jump over obstacles. </p>
                <p>Endless runners are simple, however. They usually have a bunch of objects to avoid, and this can become monotonous quickly. I wanted the game to be different each time someone plays. Implementing procedural generation was the best way to ensure a unique experience with every play.</p>
                <p>I also included a wall jump feature for changing directions, which allowed me to add vertical obstacles. Additionally, destructible terrain led to some puzzle-like level designs.</p>
            </div>
        </div>

        <div class="row">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/4DEoQALWLQ0?si=vUxfqcguun7GA0C0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            <div>
                <h2>Marketing</h2>
                <p>I ventured into marketing with two original videos: an animated trailer that I created to convey the game's backstory, and another showcasing gameplay. </p>
                <p>For the first time, I experimented with Facebook ads to promote these videos. Initially, engagement grew at a slow pace, reflecting the learning curve of leveraging social media ads effectively.</p>
            </div>
        </div>

        <div class="outcome">
                <h2>Outcome</h2>
                <div class="stats">
                    <div>
                        <h3>Life span</h3>
                        <h4>3 years</h4>
                    </div>
                    <div>
                        <h3>Downloads</h3>
                        <h4>30k+</h4>
                    </div>
                    <div>
                        <h3>Staus</h3>
                        <h4>Cancelled</h4>
                    </div>
                </div>
                <div>
                    <h3>Summary</h3>
                    <p>Over two years, I intermittently released updates for the game, balancing it with my workload. Eventually, as my professional commitments intensified, I stepped back from the game's development. To my surprise, during the three years since its release, the game had organically amassed 30,000 downloads. This unexpected growth led to a modest profit. Despite the success, I made the decision to halt further development and redirect my full attention to my next project, <a href="http://joeltankard.com/project/upproval">Upproval</a>.</p>
                </div>
        </div>
     </div>
</template>
<script>
import PreVideo from '@/components/PreVideo';

export default {
    name: 'WaffleRun',
    components: {
        PreVideo
    }
}
</script>
